import React from "react"
import { Layout, ILayoutProps } from "../components/Layout"
import { SEO } from "../components/Seo"
import { graphql, Link } from "gatsby";
import { MenuListing } from "../components/MenuListing";
import { IAppProps } from ".";

export const query = graphql`
query MenuQuery {
  dimsumSteamedJson {
    items {
      description
      id
      name
    }
  }
  dimsumFriedJson {
    items {
      description
      id
      name
    }
  }
  dimsumCheungFunJson {
    items {
      description
      id
      name
    }
  }
  dimsumRiceNoodlesJson {
    items {
      description
      id
      name
    }
  }
  dimsumDessertJson {
    items {
      description
      id
      name
    }
  }
  dimsumRiceNoodlesJson{
    items {
        description
        id
        name
    } 
  }
    metaImage: file(relativePath: {eq: "meta.jpg"}) {
    childImageSharp {
        original{src}
        }
    }
}
`;

export default class DimsumMenuPage extends React.Component<IAppProps, {}>
{
    constructor(props: IAppProps)
    {
        super(props);
    }

    public render(): JSX.Element
    {
        return (
            <Layout className="dimsum">
                <SEO title="Lunch and Dim Sum Menus" image={this.props.data.metaImage.childImageSharp.original.src} />
                <section className="hero">
                    <div className="hero-content">
                        <div className="dimsum-feature"></div>
                        <div className="content content-right">
                            <h1>Dim Sum Menu <br /></h1>
                            <p className="lead">
                                Find our full Dim Sum menu below.
                            </p>
                            <div className="button-group">
                                <Link to="/book" title="Book a table" className="btn btn-outline btn-white">
                                    Book a table
							    </Link>
                                <Link to="/menus" title="View our menus" className="btn btn-outline btn-white">
                                    View all menus
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>Dim Sum  <span>Steamed</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.dimsumSteamedJson.items} />
                        </div>

                        <div className="menu">
                            <div className="menu-feature">
                                <h1>Dim Sum  <span>Fried</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.dimsumFriedJson.items} />
                        </div>

                        <div className="menu">
                            <div className="menu-feature">
                                <h1>Dim Sum  <span>Cheung Fun</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.dimsumCheungFunJson.items} />
                        </div>

                        <div className="menu">
                            <div className="menu-feature">
                                <h1>Dim Sum  <span>Rice &amp; Noodles</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.dimsumRiceNoodlesJson.items} />
                        </div>

                        <div className="menu">
                            <div className="menu-feature">
                                <h1>Dim Sum  <span>Dessert</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.dimsumDessertJson.items} />
                        </div>
                    </div>
                </section>
            </Layout>
        );
    }
}